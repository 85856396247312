function countryAutocomplete(input, countries) {
	function closeAllLists(element) {
		let x = document.getElementsByClassName('autocomplete-items');

		for (let i = 0; i < x.length; i++) {
			if (element !== x[i] && element !== input) {
				x[i].parentNode.removeChild(x[i]);
			}
		}
	}

	function selectCountry() {
		input.value = this.getElementsByTagName('input')[0].value;

		closeAllLists();
	}

	function buildList() {
		let a;
		let b;
		let i;
		let val = this.value;

		closeAllLists();

		if (!val) {
			return false;
		}

		a = document.createElement('div');
		a.setAttribute('id', `${this.id}autocomplete-list`);
		a.setAttribute('class', 'autocomplete-items');

		this.parentNode.appendChild(a);

		for (i = 0; i < countries.length; i++) {
			if (countries[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
				b = document.createElement('div');
				b.setAttribute('class', 'autocomplete-item');
				b.innerHTML = `<strong>${countries[i].substr(0, val.length)}</strong>`;
				b.innerHTML += countries[i].substr(val.length);
				b.innerHTML += `<input type='hidden' value='${countries[i]}'>`;

				b.addEventListener('click', selectCountry);
				b.addEventListener('touchstart', selectCountry);

				a.appendChild(b);
			}
		}

		return true;
	}

	function onBlur(e) {
		if (countries.indexOf(e.target.value) < 0) {
			e.target.value = '';
		}
	}

	input.addEventListener('input', buildList);
	input.addEventListener('blur', onBlur, true);

	document.addEventListener('click', function (e) {
		closeAllLists(e.target);

		if (e.target !== input) {
			input.blur();
		}
	});
}

let countries = ['Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla',
	'Antarctica', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan',
	'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan',
	'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'British Indian Ocean Territory',
	'British Virgin Islands', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada',
	'Cape Verde', 'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China', 'Christmas Island',
	'Cocos Islands', 'Colombia', 'Comoros', 'Cook Islands', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus',
	'Czech Republic', 'Democratic Republic of the Congo', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic',
	'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia', 'Falkland Islands',
	'Faroe Islands', 'Fiji', 'Finland', 'France', 'French Polynesia', 'Gabon', 'Gambia', 'Georgia', 'Germany',
	'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guam', 'Guatemala', 'Guinea', 'Guinea - Bissau',
	'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran',
	'Iraq', 'Ireland', 'Isle of Man', 'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 'Jersey', 'Jordan',
	'Kazakhstan', 'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho',
	'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macau', 'Macedonia', 'Madagascar', 'Malawi',
	'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mayotte', 'Mexico',
	'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique', 'Myanmar',
	'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'Netherlands Antilles', 'new Caledonia', 'new Zealand', 'Nicaragua',
	'Niger', 'Nigeria', 'Niue', 'North Korea', 'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 'Palau',
	'Palestine', 'Panama', 'Papua new Guinea', 'Paraguay', 'Peru', 'Philippines', 'Pitcairn Islands', 'Poland',
	'Portugal', 'Qatar', 'Republic of the Congo', 'Romania', 'Russia', 'Rwanda', 'Saint Barthélemy', 'Saint Helena',
	'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Martin', 'Saint Pierre and Miquelon',
	'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal',
	'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia',
	'South Africa', 'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Svalbard', 'Swaziland',
	'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Timor - Leste', 'Togo',
	'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Turks and Caicos Islands',
	'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay',
	'US Virgin Islands', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Wallis and Futuna', 'Western Sahara',
	'Yemen', 'Zambia', 'Zimbabwe'];

let dropdowns = document.querySelectorAll('.country-autocomplete');

dropdowns.forEach((dropdown) => {
	countryAutocomplete(dropdown, countries);
});
